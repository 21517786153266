'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { HELP_CENTER_RETURN_TO_KEY } from '@/redesign/lib/constants';
import { useIsLoggedIn } from '@/redesign/components/hooks/useIsLoggedIn';
import { HelpCenterItem } from '@strapi-schema';
import { getLoginUrl } from '@/redesign/lib/utils/client/auth';

type RequireLoginProps = {
  item?: HelpCenterItem;
};

export const RedirectToLoginPage = ({ item }: RequireLoginProps) => {
  const router = useRouter();
  const { isLoggedIn } = useIsLoggedIn();
  useEffect(() => {
    if (!isLoggedIn && item?.loginIsRequired) {
      router.push(getLoginUrl(HELP_CENTER_RETURN_TO_KEY));
    }
  }, [isLoggedIn, item, router]);

  return null;
};
