'use client';
import { useRouter } from 'next/navigation';
import { IconButton } from '@wearemotivated/design-system/redesign/components/IconButton';
import { ArrowLeftIcon } from '@wearemotivated/design-system/redesign/icons/ArrowLeft';
import { cn } from '@/redesign/lib/utils/style';

type BackButtonProps = {
  className?: string;
};

export const BackButton = ({ className }: BackButtonProps) => {
  const router = useRouter();
  return (
    <IconButton
      variant="secondary"
      className={cn(
        'wf-h-12 wf-w-12 wf-bg-rdsn-white hover:wf-bg-rdsn-black hover:wf-text-rdsn-white [&>svg]:wf-h-3.5 [&>svg]:wf-w-3.5',
        className,
      )}
      onClick={router.back}>
      <ArrowLeftIcon />
    </IconButton>
  );
};
