import * as React from 'react';
import { ResponsiveImage } from '@wearemotivated/design-system/redesign/components/ResponsiveImage';
import { IconButton } from '@wearemotivated/design-system/redesign/components/IconButton';
import { useMobileBreakpoint } from '@wearemotivated/design-system/redesign/hooks/useTailwindBreakpoint';
import { MuteIcon } from '@wearemotivated/design-system/redesign/icons/Mute';
import { UnmuteIcon } from '@wearemotivated/design-system/redesign/icons/Unmute';
import { TestimonialsDataType } from '@/redesign/strapi-blocks/landing-pages/Testimonials';
import { cn } from '@wearemotivated/design-system/redesign/lib/utils';
import { fixLocalMedia, fixLocalUrl } from '@/redesign/lib/utils/media';

export type VideoTestimonialCardProps = {
  data: TestimonialsDataType['items'][number];
  isPlaying: boolean;
  classNames?: {
    videoContainer?: string;
    quote?: string;
  };
  onTogglePlaying: () => void;
};

export const VideoTestimonialCard = ({
  data,
  isPlaying,
  classNames,
  onTogglePlaying,
}: VideoTestimonialCardProps) => {
  const [startedOnce, setStartedOnce] = React.useState(false);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const videoMobileRef = React.useRef<HTMLVideoElement>(null);

  const isMobile = useMobileBreakpoint();

  const mobilePreviewUrl = data.previewVideoLoop?.mobile?.url;
  const desktopPreviewUrl = data.previewVideoLoop?.desktop?.url ?? '';
  const hasMobilePreview = !!mobilePreviewUrl;
  const mobileUrl = data.video?.mobile?.url ?? '';
  const desktopUrl = data.video?.desktop?.url ?? '';
  const hasMobileVideo = !!mobileUrl;

  const handleVideoClick = () => {
    if (!startedOnce) {
      setStartedOnce(true);
      videoRef.current?.play?.();
      videoMobileRef.current?.play?.();
    }
    onTogglePlaying();
  };

  // console.log('VideoTestimonialCard', { isPlaying, startedOnce });

  return (
    <div className="video-testimonial-card wf-flex wf-h-full wf-w-full wf-flex-col wf-gap-4 wf-text-rdsn-black">
      <div
        className={cn(
          'wf-relative wf-w-full wf-shrink-0 wf-cursor-pointer wf-overflow-hidden wf-rounded wf-border wf-border-rdsn-gray-100 wf-bg-rdsn-white',
          classNames?.videoContainer,
        )}
        onClick={handleVideoClick}>
        {/* main */}
        <video
          ref={videoRef}
          className={cn(
            'wf-h-full wf-w-full wf-transform-gpu wf-object-cover',
            hasMobileVideo && 'hidden sm:block',
            !startedOnce && 'wf-absolute wf-h-0 wf-w-0 wf-opacity-0',
          )}
          muted={!startedOnce || !isPlaying || (hasMobileVideo && isMobile)}
          playsInline
          loop
          preload="none">
          <source src={fixLocalUrl(desktopUrl)} />
        </video>
        {/* main mobile */}
        {hasMobileVideo ? (
          <video
            ref={videoMobileRef}
            className={cn(
              'sm:hidden wf-h-full wf-w-full wf-transform-gpu wf-object-cover',
              !startedOnce && 'wf-absolute wf-h-0 wf-w-0 wf-opacity-0',
            )}
            muted={!startedOnce || !isPlaying || !isMobile}
            playsInline
            loop
            preload="none">
            <source src={fixLocalUrl(mobileUrl)} />
          </video>
        ) : null}
        {/* preview */}
        <video
          className={cn(
            'wf-h-full wf-w-full wf-object-cover',
            hasMobilePreview && 'hidden sm:block',
            startedOnce && 'wf-hidden',
          )}
          autoPlay
          muted
          playsInline
          loop
          preload="auto">
          <source src={fixLocalUrl(desktopPreviewUrl)} />
        </video>
        {/* preview mobile */}
        {hasMobilePreview ? (
          <video
            className={cn(
              'sm:hidden wf-h-full wf-w-full wf-object-cover',
              startedOnce && 'wf-hidden',
            )}
            autoPlay
            muted
            playsInline
            loop
            preload="auto">
            <source src={fixLocalUrl(mobilePreviewUrl)} />
          </video>
        ) : null}
        <div className="video-testimonial-card-content wf-absolute wf-left-0 wf-right-0 wf-top-0 wf-flex wf-justify-between wf-px-[18px] wf-pt-[17px]">
          <div className="wf-flex wf-h-[37px] wf-items-center wf-gap-2 sm:wf-h-12">
            <div className="wf-h-8 wf-w-8 wf-overflow-hidden wf-rounded-full">
              {data.profilePicture?.url && (
                <ResponsiveImage
                  fetchPriority="low"
                  className="aspect-square w-12 object-cover"
                  desktop={fixLocalMedia(data.profilePicture)}
                />
              )}
            </div>
            <div className="wf-flex wf-flex-col wf-gap-1 wf-text-rdsn-white">
              <div className="wf-text-rdsn-h5">{data.name}</div>
              <div className="wf-text-rdsn-h7">
                {data.channelName || 'Motivated Memeber'}
              </div>
            </div>
          </div>
          <IconButton
            className="wf-flex wf-h-[37px] wf-w-[37px] wf-items-center wf-justify-center wf-rounded-full wf-bg-rdsn-black/30 hover:wf-scale-105 sm:wf-h-12 sm:wf-w-12"
            aria-label={isPlaying ? 'Mute' : 'Unmute'}>
            {isPlaying ? (
              <UnmuteIcon className="wf-text-rdsn-white" />
            ) : (
              <MuteIcon className="wf-text-rdsn-white" />
            )}
          </IconButton>
        </div>
      </div>
      <div className={cn('wf-text-rdsn-body', classNames?.quote)}>
        {data.quote}
      </div>
    </div>
  );
};
