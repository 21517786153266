import type { HelpCenterItem } from '@strapi-schema';
import kebabCase from 'lodash/kebabCase';

export const getHelpCenterItemUrl = (item: HelpCenterItem) => {
  if (item.target === 'link' && item.targetValue) {
    return item.targetValue;
  }
  if (item.target === 'new-ticket' && item.buttonText) {
    return `/support/ticket/${kebabCase(item.buttonText)}`;
  }
  if (item.target === 'submenu' && (item.items ?? []).length > 0) {
    return `/support/${kebabCase(item.buttonText as string)}`;
  }
  return '/support/ticket';
};

export const getHelpCenterItemsByTarget = (
  items: HelpCenterItem[],
  target: NonNullable<HelpCenterItem['target']>,
): HelpCenterItem[] => {
  const result = [];
  for (const item of items) {
    if (item.target === target) {
      result.push(item);
    } else if (item.target === 'submenu') {
      result.push(...getHelpCenterItemsByTarget(item.items ?? [], target));
    }
  }
  return result;
};
