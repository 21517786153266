import { TopBannerContentProps } from '@/redesign/components/product-page/TopBannerContent';
import { Media } from '@/redesign/api/strapi/types';
import { ResponsiveImage } from '@wearemotivated/design-system/redesign/components/ResponsiveImage';

type ProductMediaCarouselItemProps = {
  data: NonNullable<TopBannerContentProps['data']['carousel']>[0];
};

export const ProductMediaCarouselItem = ({
  data,
}: ProductMediaCarouselItemProps) => {
  if (data.video?.desktop.url) {
    return (
      <video
        className="w-full h-full object-cover rdsn-fade-in wf-rounded"
        src={data.video.desktop.url}
        autoPlay={true}
        preload="auto"
        muted
        loop
        playsInline
        {...(data.image?.desktop.url && { poster: data.image?.desktop.url })}
      />
    );
  }
  return (
    <ResponsiveImage
      pictureClassName="sm:wf-flex sm:wf-w-full sm:wf-h-full"
      className="rdsn-fade-in wf-rounded wf-bg-rdsn-gray-150 sm:wf-h-full sm:wf-w-full sm:wf-object-contain"
      desktop={data.image?.desktop as Media}
      mobile={data.image?.mobile}
      key={data.id}
    />
  );
};
