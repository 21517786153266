'use client';
import * as React from 'react';
import Link from 'next/link';
import { Badge } from '@wearemotivated/design-system/redesign/components/Badge';
import { MarkdownText } from '@wearemotivated/design-system/redesign/components/MarkdownText';
import { ComponentsContainerData } from '@/redesign/api/strapi/types';
import { useParams } from 'next/navigation';
import { cn } from '@wearemotivated/design-system/redesign/lib/utils';
import { BLOG_SLUG } from '@/redesign/lib/constants';

type BlogHeaderProps = {
  componentData: ComponentsContainerData['blogHeader'];
};

export const BlogHeader = ({
  componentData: { label, logoText, description, categories },
}: BlogHeaderProps) => {
  const { slug: currentSlug } = useParams();
  const isBlogHomepage = !currentSlug;
  const selectedCategory = categories.find(
    (item) => !!item.slug && item.slug === currentSlug,
  );
  const isCategoryPage = !!selectedCategory;

  return (
    <div className="wf-mb-10 wf-flex wf-flex-col wf-items-center wf-gap-y-6 wf-overflow-clip wf-px-4 wf-pt-[30px] wf-font-gothic wf-text-rdsn-black sm:wf-pt-14">
      {label ? <Badge>{label}</Badge> : null}
      <MarkdownText className="-wf-my-2 wf-text-[72px] wf-leading-none wf-tracking-[-0.04em] sm:wf-text-[96px]">
        {logoText}
      </MarkdownText>
      <p className="wf-whitespace-pre-line wf-text-center wf-text-rdsn-body">
        {description}
      </p>
      <div className="wf-mx-auto wf-w-full wf-max-w-6xl">
        <hr className="wf-mx-0 wf-border-rdsn-gray-400 xl:-wf-mx-4" />
      </div>
      {categories?.length ? (
        <div className="-wf-mb-2 wf-w-dvw wf-pl-4">
          <div className="scrollbar-hide wf-mx-auto wf-flex wf-w-fit wf-max-w-full wf-flex-row wf-gap-x-4 wf-overflow-x-auto wf-pb-2">
            {categories.map((item) => {
              const isActive = !!item.slug && item.slug === currentSlug;
              return (
                <Link
                  href={`/${BLOG_SLUG}/${item.slug}`}
                  key={item.id}
                  className={cn(
                    'wf-flex wf-w-fit wf-shrink-0 wf-flex-row wf-items-center wf-text-nowrap wf-rounded-full wf-px-4 wf-py-3 wf-text-rdsn-body hover:wf-bg-rdsn-gray-100',
                    isActive &&
                      'wf-border wf-border-rdsn-black wf-bg-rdsn-gray-100',
                  )}>
                  {isActive && (
                    <span className="wf-mr-2 wf-inline-flex wf-size-2 wf-rounded-full wf-bg-rdsn-black" />
                  )}
                  {item.title}
                </Link>
              );
            })}
          </div>
        </div>
      ) : null}
      <div className="wf-mx-auto wf-w-full wf-max-w-6xl">
        <hr className="wf-mx-0 wf-border-rdsn-gray-400 xl:-wf-mx-4" />
      </div>
      {isCategoryPage ? (
        <>
          <h2 className="wf-mt-4 wf-text-rdsn-h1">{selectedCategory?.title}</h2>
          {selectedCategory.description ? (
            <p className="-wf-mt-2 wf-whitespace-pre-line wf-text-center wf-text-rdsn-body">
              {selectedCategory.description}
            </p>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
