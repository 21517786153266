import { parseProductData } from '@/redesign/lib/utils/product';
import {
  formatPrice,
  formatShortPrice,
} from '@wearemotivated/design-system/redesign/lib/utils';
import { usePricingCalculator } from '@/redesign/strapi-blocks/landing-pages/PricingCalculator/PricingCalculatorContext';

type PricingCalculatorTotalProps = {
  productVariants: ReturnType<typeof parseProductData>;
};

const format = (n: number) => {
  return n % 1 !== 0 ? formatPrice(n, 'USD') : formatShortPrice(n, 'USD');
};

export default function PricingCalculatorTotal({
  productVariants,
}: PricingCalculatorTotalProps) {
  const { values } = usePricingCalculator();
  const strength = values.strength?.value;
  const product = values.product?.value;
  const quantity = values.quantity;
  const frequency = values.frequency;
  if (!strength || !product || !quantity || !frequency) return null;
  const quantityVariant = productVariants.dosages
    .get(strength)
    ?.products.get(product)
    ?.quantities.get(quantity.value);
  const frequencyVariant = quantityVariant?.frequencies.get(frequency.value);

  const total = frequencyVariant?.totalPrice as number;
  const months = frequencyVariant?.numericalValue as number;
  const pillsPerMonth = quantityVariant?.numericalValue as number;

  return (
    <div className="wf-my-8 wf-min-h-[2.125rem] wf-space-x-2">
      <strong className="wf-text-rdsn-h1 wf-font-medium wf-text-rdsn-black">
        {format(frequencyVariant?.totalPrice as number)}
      </strong>
      <span className="wf-text-rdsn-body wf-text-rdsn-black">
        {format(total / (months * pillsPerMonth))}/tablet
      </span>
    </div>
  );
}
