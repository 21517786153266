'use client';

import { useRouter } from 'next/navigation';
import { ArrowLeftIcon } from '@wearemotivated/design-system/redesign/icons/ArrowLeft';
import { Button } from '@wearemotivated/design-system/redesign/components/Button';

type HelpCenterSubmenuHeaderDesktopProps = {
  parentTitle: string;
};

export const HelpCenterSubmenuHeaderDesktop = ({
  parentTitle,
}: HelpCenterSubmenuHeaderDesktopProps) => {
  const router = useRouter();
  return (
    <div className="wf-flex wf-h-5 wf-items-center wf-gap-x-6 max-md:wf-hidden">
      <Button
        variant="unstyled"
        className="wf-h-full wf-p-0"
        onClick={router.back}>
        <ArrowLeftIcon />
      </Button>
      <span className="wf-h-3.5 wf-w-[1px] wf-bg-rdsn-gray-400" />
      <span className="wf-text-rdsn-h4">{parentTitle}</span>
    </div>
  );
};
