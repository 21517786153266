/**
 * constants and very simple functions using those contants
 */

export const SURVEY_SAVED_ID_COOKIE = 'motivated:last_survey_id';

export const WIZARD_PATH = '/evaluation/';
export const DEFAULT_CONTINUE_SURVEY = `${WIZARD_PATH}ed`;
export const SAVE_KEY_PREFIX = 'motivated:survey_';

export const MAGIC_TOKEN_PARAM_NAME = 'token';

export const buildSurveyUrl = (wizardSlug: string) => {
  return `${WIZARD_PATH}${wizardSlug}`;
};

export const IPADDR_COOKIE_NAME = 'motivated:ipaddr';
export const IPADDR_COOKIE_OPTIONS = {
  httpOnly: true,
  sameSite: 'lax',
  path: '/',
  // When working on localhost, the cookie domain must be omitted entirely (https://stackoverflow.com/a/1188145)
  domain: process.env.AUTH_COOKIE_DOMAIN
    ? process.env.AUTH_COOKIE_DOMAIN
    : undefined,
} as const;

export const LOGIN_URL = process.env.NEXT_PUBLIC_PORTAL_HOST + '/login';
export const ACCOUNT_URL =
  process.env.NEXT_PUBLIC_PORTAL_HOST + '/my-treatments';
export const LOGOUT_URL =
  process.env.NEXT_PUBLIC_PORTAL_HOST + '/portal/logout';

export const REDESIGN_FOOTER_ID = 3;

export const HELP_CENTER_RETURN_TO_KEY = 'help-center';

export const REFERRER_COOKIE_NAME = 'motivated:referrer';
export const USER_LOGGED_IN_COOKIE_NAME = 'motivated:user_logged_in';

export const bgcolorMap = {
  white: { className: 'wf-bg-rdsn-white', colorValue: '#FFFFFF' },
  gray: { className: 'wf-bg-rdsn-gray-100', colorValue: '#F7f7f7' },
  yellow: { className: 'wf-bg-rdsn-yellow', colorValue: '#fff88d' },
} as const;

export type BgColorType = keyof typeof bgcolorMap | (string & {});

export const BLOG_SLUG = 'blog';
