'use client';
import * as React from 'react';
import { getProfile, setUserIdentify } from '@/app/utils/auth';
import { UserProfileType } from '@/redesign/lib/types/auth';
import { USER_LOGGED_IN_COOKIE_NAME } from '@/redesign/lib/constants';
import { hasCookie } from '@/redesign/lib/utils/client/cookie';

type UserProfileContextType = {
  userProfile: UserProfileType | null;
  isLoggedIn: boolean;
};

type UserProfileProviderProps = {
  children: React.ReactNode;
};

export const UserProfileContext = React.createContext<UserProfileContextType>({
  userProfile: null,
  isLoggedIn: false,
});

export const UserProfileProvider = ({ children }: UserProfileProviderProps) => {
  const [userProfile, setUserProfile] = React.useState<UserProfileType | null>(
    null,
  );
  const [isLoggedIn, setIsLoggedIn] = React.useState(() =>
    hasCookie(USER_LOGGED_IN_COOKIE_NAME),
  );

  React.useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    (async () => {
      const profile = await getProfile(signal, false);
      if (profile) {
        setUserIdentify(profile.customerId, profile.email);
        setUserProfile(profile);
      }
      if (!controller.signal.aborted) {
        setIsLoggedIn(Boolean(profile));
      }
    })();

    return () => {
      // because of strict mode
      controller.abort();
    };
  }, []);

  return (
    <UserProfileContext
      value={{
        userProfile,
        isLoggedIn,
      }}>
      {children}
    </UserProfileContext>
  );
};
