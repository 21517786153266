'use client';
import Link from 'next/link';
import { Badge } from '@wearemotivated/design-system/redesign/components/Badge';
import { useIsLoggedIn } from '@/redesign/components/hooks/useIsLoggedIn';
import { HelpCenterHeading } from '@/redesign/strapi-blocks/support/HelpCenter/HelpCenterHeading';
import type { ComponentsContainerData } from '@/redesign/api/strapi/types';
import { Button } from '@wearemotivated/design-system/redesign/components/Button';
import { ArrowRightIcon } from '@wearemotivated/design-system/redesign/icons/ArrowRight';
import { getLoginUrl } from '@/redesign/lib/utils/client/auth';
import { HELP_CENTER_RETURN_TO_KEY } from '@/redesign/lib/constants';
import { LoaderIcon } from '@wearemotivated/design-system/redesign/icons/Loader';

type HelpCenterHeaderProps = Pick<
  ComponentsContainerData['helpCenterMenu'],
  | 'label'
  | 'titleForGuests'
  | 'subtitleForGuests'
  | 'titleForMembers'
  | 'loginButton'
>;

const isClient = typeof window !== 'undefined';

export const HelpCenterHeader = ({
  label,
  titleForGuests,
  subtitleForGuests,
  titleForMembers,
  loginButton = 'Log In',
}: HelpCenterHeaderProps) => {
  const { isLoggedIn, userProfile } = useIsLoggedIn();
  return (
    <div className="wf-flex wf-flex-col wf-items-center wf-gap-y-6">
      {label && <Badge>{label}</Badge>}
      {!isLoggedIn ? (
        <>
          <HelpCenterHeading>{titleForGuests}</HelpCenterHeading>
          {isClient ? (
            <>
              <p className="wf-text-center wf-text-rdsn-body">
                {subtitleForGuests}
              </p>
              <Button className="wf-min-w-52 wf-px-10" asChild>
                <Link href={getLoginUrl(HELP_CENTER_RETURN_TO_KEY)}>
                  {loginButton}{' '}
                  <ArrowRightIcon className="wf-ml-2 wf-h-3 wf-w-3.5" />
                </Link>
              </Button>
            </>
          ) : (
            <LoaderIcon height="14" />
          )}
        </>
      ) : (
        <HelpCenterHeading>
          {userProfile?.firstName
            ? titleForMembers?.replace(
                '{firstName}',
                userProfile?.firstName != 'Guest'
                  ? userProfile.firstName
                  : 'there',
              )
            : titleForGuests}
        </HelpCenterHeading>
      )}
    </div>
  );
};
